import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/api';
import axios from '@/plugins/common/axios';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';

export const useStoreSearchIndex = defineStore({
    id: 'search/index',

    state: () => ({
        loaded: false,
        pending: false,
        changed: false,
        data: [],
        form: {
            q: '',
        },
        pagination: null,
    }),

    getters: {
        //
    },

    actions: {
        async fetch(data: any, callback: any) {
            const self = this;
            self.pending = true;
            const url = api.search;
            if (data.page === undefined) {
                data.page = 1;
            }
            await axios.get(url, data, (result: any) => {
                self.pending = false;
                self.loaded = true;
                self.margeData(result.data);
                self.pagination = result.pagination;

                if (result.breadcrumbs !== undefined) {
                    useStoreAppBreadcrumbsIndex().create(result.breadcrumbs);
                }
                if (self.changed) {
                    self.changed = false;
                }
                callback(self.data);
            }, () => {
                //
            });
        },
        setForm(data: object) {
            this.form = Object.assign({}, this.form, data);
        },
        setLoaded(val: boolean) {
            this.loaded = val;
        },
        margeData(data: []) {
            this.data = [...this.data, ...data];
        }
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreSearchIndex, import.meta.hot));
}
